/* eslint-disable react-hooks/rules-of-hooks */
import { DialogActions, DialogContent, DialogTitle, Typography, } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { Autocomplete, Button, Checkbox, Dialog, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField, } from "@mui/material";
import React, { useState } from "react";
import { useGlobalState } from "../../GlobalStore/globalStore";
import MLearnTooltip from '../../GlobalStyles/ToolTip';
import { IUserEmail } from "../../model/Users/IUser";
import { usePost } from "../../utils/apiHelper";
import useStyles from "./ManagerCss";

const AssignUser: React.FC<{ onCreate: () => void, userList, userDetail }> = (props) => {
  const { state } = useGlobalState();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [managerEmail, setManagerEmails] = useState<string>();
  const [userEmail, setUserEmail] = useState<IUserEmail[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<IUserEmail[]>([]);
  const admin = state.userAccessContext.userRole && state.userAccessContext.isManageCourse && state.userAccessContext.isManageUser;

  let intialData: IUserEmail[] = [{
    userId: 0,
    emailAddress: "",
    checked: false,
  }];

  const handleChange = (event, value) => {
    setSelectedOptions(value);
  };

  const handleCheckboxChange = (userId, isChecked) => {
    if (isChecked) {
      setSelectedOptions([...selectedOptions, userEmail.find(user => user.userId === userId)]);
    } else {
      setSelectedOptions(selectedOptions.filter(user => user.userId !== userId));
    }
  };



  const handleClickOpen = () => {
    if (admin) {
      setUserEmail(props.userList);
    } else {
      // Assuming userId is available
      const filteredData = props.userList.filter(user => user.userId === state.userAccessContext.userId);
      setUserEmail(filteredData);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setManagerEmails("");
    setSelectedOptions(intialData);
  };

  const handleSubmit = async () => {
    const mangeLearnerMapping = selectedOptions?.map((code) => ({
      'userId': code.userId,
    }));

    (async () => {
      let request = {
        "managerId": managerEmail, // Assuming managerEmail holds the managerId value
        "learnerIds": mangeLearnerMapping // Use mangeLearnerMapping directly
      };
      await usePost("UserCourse/InsertManagerLearner", request).then(() => {
        props.onCreate()
        setOpen(false)
      }).finally(() => { setManagerEmails(''); setSelectedOptions(intialData) });
    })()
  };
  return (
    <React.Fragment>
      <MLearnTooltip title="Assign User" placement="top">
        <Button
          size="small"
          variant="contained"
          color="success"
          onClick={handleClickOpen}
          className={classes.assignUserStyle}
          aria-label="Assign User"
        >
          <GroupAddIcon />
        </Button>
      </MLearnTooltip>
      <Dialog open={open} onClose={handleClose} maxWidth={"md"} PaperProps={{ className: classes.dialog }} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" style={{ backgroundColor: "#5e35b1", color: "white" }} className={`${classes.textAlign} ${classes.dialogTitle}`}>
          <b>Map Manager - Learner</b>
          <CancelOutlinedIcon onClick={handleClose} className={classes.closeButton} />
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Manager Email Address
                </InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Manager Email Address" value={managerEmail}
                  onChange={e => setManagerEmails(e.target.value)}
                >
                  {userEmail.map((email) => (
                    <MenuItem key={email?.emailAddress} value={email?.userId} >
                      {email?.emailAddress}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="body2">
                <b>Assign Learners</b>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Autocomplete
                size="small"
                multiple
                id="checkboxes-tags-demo"
                options={admin ? props.userList : props.userDetail}
                disableCloseOnSelect
                getOptionLabel={(option) => option.emailAddress}
                onChange={handleChange}
                value={selectedOptions}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <FormControlLabel
                      control={
                        <Checkbox style={{ marginRight: 8 }} checked={selected} size="small" onChange={(e) => handleCheckboxChange(option.userId, e.target.checked)} />
                      }
                      label={option.emailAddress}
                    />
                  </li>
                )}
                style={{ width: 500 }}
                renderInput={(params) => (
                  <TextField {...params} label="Assign Learners" placeholder="Select Learners" size="small" style={{ width: "660px" }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" size="small" color="success" onClick={handleSubmit} startIcon={<CheckCircleIcon />}>
            <b>Add</b>
          </Button>
          <Button onClick={handleClose} variant="contained" size="small" color="error" startIcon={<CancelIcon />}>
            <b>Cancel</b>
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default AssignUser;
