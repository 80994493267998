/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from 'react';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import logo from "../asset/image/Login/Cog_Logo.png";
import facebook from "../asset/image/Login/Logo/facebook-color.svg";
import google from "../asset/image/Login/Logo/google-color.svg";
import microsoft from "../asset/image/Login/Logo/microsoft.svg";
import slide3 from "../asset/image/Login/Student5.svg";
import slide1 from "../asset/image/Login/Student6.svg";
import slide2 from "../asset/image/Login/admin1.svg";
// Login
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Button, CardContent, Grid, Hidden, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { NameRegex, PhoneRegex, emailRegex } from '../../src/constant/Constant';
import { loginRequest } from '../auth/authConfig';
import { IProspectUsersDetails, IUserDetails } from "../model/ProspectUsersContract/ProspectUsersDetails";
//api 
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";
import { GlobalStateAction, useGlobalState } from "../GlobalStore/globalStore";
import { IUserInfo } from "../model/Users/IUserContext";
import ReCaptchaComponent from "../utils/ReCaptcha";
import { usePost } from "../utils/apiHelper";
import AdminLayout from "./AdminLayout";

const useStyles = makeStyles((theme) => ({

    textFiled: {
        marginTop: "5px",
        "& .MuiFormLabel-root": {
            fontSize: 13,
        },
        '& .MuiFormHelperText-root': {
            fontSize: 10,
        }
    },
    socialIcons: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        paddingBottom: "5px",
        paddingTop: "5px",
    },
    facebookStyle: {
        backgroundColor: "#385998",
        boxShadow: "0 5px 4px rgba(0, 0, 0, 0.5)",
    },
    microsoftStyle: {
        backgroundColor: "#e9eaec",
        boxShadow: "0 5px 4px rgba(0, 0, 0, 0.5)",
    },

    googleStyle: {
        backgroundColor: "#CA3224",
        boxShadow: "0 5px 4px rgba(0, 0, 0, 0.5)",
    },

    image: {
        width: "18px",
        height: "18px"
    },
    disabledStyle: {
        marginTop: "5px",
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "gray",
            color: "gray",
            border: '2px solid gray',
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "gray",
            color: "gray",
        },
        "& .MuiInputLabel-outlined": {
            color: "gray",
        },
        "& .MuiOutlinedInput-input": {
            color: "gray"
        },
    },
    rootError: {
        marginTop: "5px",
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "red",
            color: "red",
            border: '2px solid red',
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "red",
            color: "red",
        },
        "& .MuiInputLabel-outlined": {
            color: "red",
        },
        "& .MuiOutlinedInput-input": {
            color: "red"
        },
    },
    ButtonStyle: {
        marginTop: "5px",
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "green",
            color: "green",
            border: '2px solid green'
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "green",
            color: "green"
        },
        "& .MuiInputLabel-outlined": {
            color: "green"
        },
        "& .MuiOutlinedInput-input": {
            color: "green"
        }
    },
    continuebutton: {
        fontWeight: 'bold',
        backgroundColor: '#065ad8 !important',
        color: 'white !important',
        width: '80%',
        padding: '8px',
        borderRadius: '5px',
        border: 'none',
        marginTop: '10px',
        cursor: 'pointer',
        '&:hover': {
            border: 'none',
            padding: '8px',
            borderRadius: '5px',
            backgroundColor: '#3bb78f',
            boxShadow: '0px 15px 20px rgba(46, 229, 157, 0.4)',
        }
    },
    noteInfo: {
        background: 'linear-gradient(135deg, rgba(91, 228, 155, 0.2), rgba(0, 167, 111, 0.2)) rgb(255, 255, 255)',
        borderRadius: '5px',
        color: 'rgb(0, 75, 80)',
        padding: '10px 15px',
        fontSize: '13px'
    },

    //loading design
    loader: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '6vh',
    },
    capcha: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '16px !important',
        borderRadius: '5px',
        marginLeft: '9px !important',
        padding: '5px',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        background: 'whiteSmoke'
    },
    OtherSign: {
        backgroundColor: 'white !important', color: 'black!important', '&:hover': { backgroundColor: 'white !important' }
    },
    CarouselImage: {
        height: '70vh', width: '80%',
        [theme.breakpoints.down('xs')]: {
            height: '200px',
        },
    },

    CarouselContainer: {
        backgroundColor: "#065ad8",
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    CarouselContent: {
        textAlign: 'center',
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1rem',
        },
    }
}));

const Login2: React.FC = () => {
    const classes = useStyles();
    const { accounts, instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    let history = useHistory();
    const [showSignin, setShowSigIn] = useState(false);
    const [showSignup, setShowSigup] = useState(false);
    const [isDisable, setIsDisable] = useState(false);
    const [userDetailsList, setUserDetailsList] = useState<IProspectUsersDetails>();
    const [showPassword, setShowPassword] = useState(false);
    const [ProgressBar, setShowProgressBar] = useState<boolean>(false);
    const [isPasswordProvided, setIsPasswordProvided] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [isSignedupUser, setIsSignedupUser] = useState<boolean>(false);
    const [prospectEmailError, setProspectEmailError] = useState<string>("");
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const [emailerror, setEmailError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [lastnameerror, setLastNameError] = useState("");
    const [firstnameerror, setFirstNameError] = useState("");
    const [isAccountCreated, setIsAccountCreated] = useState<boolean>(false);
    const [userType, setUserType] = useState(0);
    const [active, setActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emailAddressActive, setEmailAddressActive] = useState("");
    const { dispatch } = useGlobalState();
    const [subscriptionKey, setSubscriptionKey] = useState<string>("");
    const [subscriptionResult, setSubscriptionResult] = useState(false);
    const [showSubscriptionMessage, setShowSubscriptionMessage] = useState(false);
    const [captchaLoaded, setCaptchaLoaded] = useState<boolean>(false);
    const [captchaResponse, setCaptchaResponse] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    let newUsersList: IUserDetails = {
        prospectEmailAddress: "",
        prospectFirstName: "",
        prospectLastName: "",
        prospectPhoneNumber: "",
        organizationName: "",
        organizationAdminEmailId: "",
        organizationPhoneNumber: "",
        password: "",
        confirmPassword: "",
        userName: ""
    }
    // localState to store the user information 
    const [localState, setLocalState] = useState<IUserDetails>(newUsersList);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

    const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    useEffect(() => {
        setIsDisable(inProgress === 'login' || inProgress === 'handleRedirect' || inProgress === 'startup')
    }, [inProgress]);

    useEffect(() => {
        window.onbeforeunload = function () {
            return true;
        };
    }, []);

    // this functions is to validate 
    useEffect(() => {
        if (isAuthenticated) {
            let account = accounts[0];
            instance.ssoSilent({ ...loginRequest, loginHint: account.username }).then(a => {
            });
        }
    }, [isAuthenticated])

    const handleSignup = () => {
        setShowSigIn(false);
        setShowSigup(true);
        setCaptchaLoaded(true);
    };

    let isSubscribed: number;
    const handleSignin = async () => {

        setShowSigup(false);
        setLoading(false);
        if (!inProgress && accounts.length > 0) {
            const account = accounts[0];
            if (account) {
                try {
                    instance.ssoSilent(loginRequest).then(a => {
                        history.push('/dashboard');
                    })
                }
                catch (exception) {
                    instance.loginPopup(loginRequest)
                }
            }
            setLoading(false);
        }
        else {
            setLoading(true);
            instance.loginPopup(loginRequest).then(a => {
                const request = {
                    "userEmailId": a.account.username,
                    "isSubscribed": null
                };
                (async () => {
                    await usePost<IUserInfo>("Users/ValidateActiveUser", request).then((response) => {
                        const user = response.data;
                        if (user?.isSubscribed === 0 || user.isSubscribed === null) {
                            setActive(true);
                            setLoading(false);
                        }
                        else {
                            dispatch({ type: GlobalStateAction.userAccessContext, userAccessContext: user, });
                            if (user.userRole || user.isManager) {
                                history.push('/dashboard');
                            } else {
                                history.push('/myCourse');
                            }

                            isSubscribed = user.isSubscribed;
                            setUserType(user.userRole);
                            setEmailAddressActive(user?.emailAddress)
                            setShowSigIn(true);
                            setLoading(false);
                        }
                    });
                })()
            }).catch(() => { setLoading(false) })
        }
    };

    const handleSignupClick = () => {
        if (isSubmitting) return;


        if (!localState?.userName?.length || !localState?.prospectFirstName?.length || !localState?.prospectLastName?.length || !localState?.prospectPhoneNumber?.length
            || !!firstnameerror?.length || !!lastnameerror?.length || !!emailerror?.length || !!phoneError?.length ||
            !localState?.password?.length || !localState?.confirmPassword?.length) {
            if (localState?.prospectFirstName === "") {
                setFirstNameError("First name is required");
            }
            if (localState?.prospectLastName === "") {
                setLastNameError("Last name is required");
            }
            if (localState?.prospectPhoneNumber === "") {
                setPhoneError("Phone number is Required");
            }
            if (localState?.confirmPassword === "") {
                setConfirmPasswordError("Confirm Password is required");
            }
            if (localState?.password === "") {
                setPasswordError("Password is required");
            }
            if (localState?.userName === "") {
                setProspectEmailError("User Email is Required");
            }
            setIsSubmitting(false); // Re-enable button if validation fails
            return;
        } else if (localState?.password !== localState?.confirmPassword) {
            setConfirmPasswordError("Password and Confirm Password didn’t match. Try again.");
            setLocalState({ ...localState, confirmPassword: "" });
            setIsSubmitting(false); // Re-enable button if passwords do not match
            return;
        } else {
            handleSaveUserInformation().finally(() => setIsSubmitting(false))
        }
    };


    const handleSaveUserInformation = async () => {

        setIsSubmitting(true);
        const userEmail = !isPasswordProvided ? localState?.prospectEmailAddress : localState?.userName;
        setShowProgressBar(true);
        try {
            const response = await fetch("https://merrylearnapi.azurewebsites.net/api/MSEntra/CheckEmailExist?userEmail=" + userEmail);
            const data = await response.json();
            if (!isPasswordProvided) {
                setEmailError("Email Already Exist in Azure AD try with another email");
            } else if (isPasswordProvided) {
                setProspectEmailError("Email Already Exist in Azure AD try with another email");
            }
            setShowProgressBar(false);
            console.log(data); // log the data to the console
        } catch (error) {

            /**
            * Calling the azure function to insert the Prospect Users Information
            * Need to write a logic to validate the account is exist in the system(DB) or not 
            */


            /**
              * Call the graph API to send an invitation to Signup user
              * 
              * Calling the graph API to Create a new user in the Azure AD
              */

            setLoading(true);
            try {

                (async () => {
                    let request = {
                        "fullName": localState?.prospectFirstName + " " + localState?.prospectLastName,
                        "userEmail": !isPasswordProvided ? localState?.prospectEmailAddress : localState?.userName,
                        "givenName": localState?.prospectFirstName,
                        "surname": localState?.prospectLastName,
                        "password": localState?.password
                    }
                    await fetch("https://cognultsoftapiecosystem-merrylearn-h6g5akbuetdyafd9.eastus-01.azurewebsites.net/api/MSEntra/SendSignupInvitation", {

                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(request),
                    }
                    );

                })()

                let PostUsr = {
                    "userId": 0,
                    "firstName": localState?.prospectFirstName,
                    "lastName": localState?.prospectLastName,
                    "emailAddress": localState?.userName,
                    "isSubscribed": false,
                    "userRole": 0

                }
                await axios.post('https://cognultsoftapiecosystem-merrylearn-h6g5akbuetdyafd9.eastus-01.azurewebsites.net/api/Users/ManageUser', PostUsr).finally;
            } catch (error) {
                console.log(error); // log the data to the console
            }
            setLoading(false);
            setActive(false);
            setEmailError("");
            handleMapUserDetails();
            setShowProgressBar(false);
            setIsAccountCreated(true);
            setShowSigup(false);
        }
    }

    const handleClose = () => {
        setIsAccountCreated(false);
    }

    const validatePassword = (password: any) => {
        // Password validation criteria
        const minLength = 8;
        const regexUpperCase = /[A-Z]/;
        const regexLowerCase = /[a-z]/;
        const regexSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

        // Check minimum length
        if (password.length < minLength) {
            return "Password must be at least 8 characters long";
        }

        // Check for uppercase letters
        if (!regexUpperCase.test(password)) {
            return "Password must contain at least one uppercase letter";
        }

        // Check for lowercase letters
        if (!regexLowerCase.test(password)) {
            return "Password must contain at least one lowercase letter";
        }

        // Check for special characters
        if (!regexSpecialChar.test(password)) {
            return "Password must contain at least one special character";
        }
        // Return empty string if password is valid
        return "";
    };

    const handleMapUserDetails = () => {
        let newUserDetails: IProspectUsersDetails = {
            prospectEmailAddress: localState?.prospectEmailAddress,
            prospectGivenName: "",
            prospectLastName: "",
            prospectDisplayName: "",
            prospectPhoneNumber: "",
            isNewOrginization: false,
            isWebviewUser: false,
            isClientOnboarded: false,
            isUserActivated: false,
            isPermissionUpdated: false,
            isDocumentUploaded: false,
            isAdminApproved: false,
            isClientInfoConfirmed: false,
            prospectUserId: 0,
            prospectCreatedDate: new Date(),
            organizationName: "",
            organizationAdminEmailId: "",
            organizationPhoneNumber: "",
            isClientSetup: false,
            isActive: false,
            isMultiBranch: false
        }
        setUserDetailsList(newUserDetails);
        setIsSignedupUser(true);
    }

    const HandleFirstNameVal = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const FirstNameVal = e.target.value;
        if (FirstNameVal.match(NameRegex)) {
            setFirstNameError("");
        } else if (FirstNameVal === "") {
            setFirstNameError("First name is required");
        } else {
            setFirstNameError("Please enter characters only");
        }
    };

    const HandleLastNameVal = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const LastNameVal = e.target.value;
        if (LastNameVal.match(NameRegex)) {
            setLastNameError("");
        } else if (LastNameVal === "") {
            setLastNameError("Last name is required");
        } else {
            setLastNameError("Please enter characters only");
        }
    };

    const HandleUserEmailVal = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setIsPasswordProvided(true);
        setEmailError("");
        const EmailVal = e.target.value;
        if (EmailVal.match(emailRegex)) {
            setProspectEmailError(""); // log the data to the console
        } else if (EmailVal === "") {
            setProspectEmailError("Email address is required");
        } else {
            setProspectEmailError("Please enter valid email address");
        }
    };

    const HandlePhoneVal = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const PhoneVal = e.target.value;
        if (PhoneVal.match(PhoneRegex)) {
            setPhoneError("");
        } else if (PhoneVal === "") {
            setPhoneError("Phone number is Required");
        } else {
            setPhoneError("Please enter numbers only");
        }
    };

    const handleVerifyKey = () => {
        (async () => {
            setLoading(true);
            let request = {
                subscriptionKey: subscriptionKey,
                productKey: 1
            }
            await usePost<boolean>("https://cognultsoftapiecosystem-e7a2cqg6brb8ddad.eastus-01.azurewebsites.net/api/PlatformAdmin/ValidateProductSubscription", request).then(async (result) => {

                setSubscriptionResult(result?.data);
                if (result?.data === true) {
                    const a = await instance.loginPopup(loginRequest).then(async (a) => {
                        const request = { UserEmailId: a.account.username, 'IsSubscribed': isSubscribed };
                        setShowSubscriptionMessage(false);
                        const requests = {
                            "userEmailId": a.account.username,
                            "isSubscribed": 0
                        };
                        await usePost<IUserInfo>("Users/ValidateActiveUser", requests).then((response) => {
                            const user = response.data;
                            dispatch({ type: GlobalStateAction.userAccessContext, userAccessContext: user, });
                            isSubscribed = user.isSubscribed;
                            setUserType(user.userRole);
                            setEmailAddressActive(user?.emailAddress)
                            setShowSigIn(true);
                        })
                    }).finally(() => { });
                } else {
                    setShowSubscriptionMessage(true);
                }
            })
        })().finally(() => {
            setLoading(false);
        })
    };

    const handleValidatePassword = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        // Call password validation function
        const error = validatePassword(event.target.value);
        // Set error message if password is invalid, otherwise clear error message
        if (error) {
            setPasswordError(error);
        } else {
            setPasswordError("");
        }
    }

    // Check if password and confirm password are the same
    const handleConfirmPasswordChange = (event: any) => {
        // Check if password and confirm password are the same
        if (localState?.password !== event?.target?.value) {
            setConfirmPasswordError("Password and Confirm Password didn’t match. Try again.");
        } else {
            setConfirmPasswordError("");
        }
    }

    // to get the user details
    const handleGetProspectUserDetails = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let name = event.target.name;
        setLocalState({
            ...localState,
            [name]: event.target.value,
        });
    }

    const handleCaptchaVerify = (dataFromChild) => {
        // Use the data from the child component in your condition
        if (dataFromChild?.length) {
            setCaptchaResponse(dataFromChild);
        } else if (!dataFromChild?.length) {
            setCaptchaResponse(null);
        }
    };
    const signOptions = [
        {
            imgSrc: google,
            alt: "google",
            label: "Google"
        },
        {
            imgSrc: microsoft,
            alt: "microsoft",
            label: "Microsoft"
        },
        {
            imgSrc: facebook,
            alt: "facebook",
            label: "FaceBook"
        }
    ];

    function Icon() {
        return (
            <Grid container spacing={1} justifyContent={"space-evenly"}>
                {signOptions.map((option, index) => (
                    <Grid item xs={12} sm={4} md={4} lg={4} key={index}>
                        <Button variant="contained" fullWidth className={classes.OtherSign} startIcon={<img src={option.imgSrc} alt={option.alt} className={classes.image} />} >
                            <b>{option.label}</b>
                        </Button>
                    </Grid>
                ))}
            </Grid >
        );
    }
    const slides = [
        {
            imgSrc: slide1,
            alt: "Admin Dashboard",
            text: "Effortless Administration: Craft Courses, Assign Tasks, <br />and Oversee Performance with Ease",
            height: '90vh'
        },
        {
            imgSrc: slide2,
            alt: "Course Management",
            text: "<b>Streamlined Course Management: Simplify Course Creation and <br />Allocation for Optimal Learning</b>",
            height: '80vh'
        },
        {
            imgSrc: slide3,
            alt: "User Learning Experience",
            text: "Engaging Learning Journey: Access Courses, Complete Assessments,<br /> and Achieve Certifications",
            height: '80vh'
        }
    ];

    return (
        <React.Fragment>
            {!showSignin ?
                <Grid container spacing={0}>
                    <Hidden smDown>
                        <Grid item xs={12} sm={8}>
                            <Grid container className={classes.CarouselContainer}>
                                <Carousel
                                    autoPlay
                                    showThumbs={false}
                                    showStatus={false}
                                    showArrows={false}
                                    infiniteLoop
                                    animationHandler="slide"
                                >
                                    {slides.map((slide, index) => (
                                        <Grid key={index} height={slide.height}>
                                            <img src={slide.imgSrc} className={classes.CarouselImage} alt={slide.alt} />
                                            <CardContent>
                                                <Typography variant="h6" className={classes.CarouselContent}>
                                                    <span dangerouslySetInnerHTML={{ __html: slide.text }} />
                                                </Typography>
                                            </CardContent>
                                        </Grid>
                                    ))}
                                </Carousel>
                            </Grid>
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} sm={4} p={4} minHeight='100vh' display={'flex'} justifyContent={'center'} alignItems={'center'} alignContent={'center'}>
                        {showSignup ?
                            <Grid container spacing={1} >
                                <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'} p={1}>
                                    <img src={logo} height="50px" alt="companylogo" />
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography variant="h5" color="#283593" pt={1} gutterBottom textAlign={'center'}><b>Sign Up with Merry Learn</b></Typography>
                                </Grid>
                                <Grid item xs={12} mt={1}>
                                    <TextField
                                        id="outlined-basic"
                                        required
                                        name="userName"
                                        label="Email (Login user ID)"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        disabled={!!localState?.prospectEmailAddress?.length}
                                        className={!!localState?.prospectEmailAddress?.length ? classes.disabledStyle : prospectEmailError ? classes.rootError : classes.ButtonStyle}
                                        inputProps={{ maxLength: 50 }}
                                        onChange={(e) => { HandleUserEmailVal(e); handleGetProspectUserDetails(e); }}
                                        helperText={prospectEmailError}
                                        error={!!prospectEmailError}
                                    />
                                </Grid>
                                <Grid item xs={6} mt={1}>
                                    <TextField
                                        id="outlined-basic"
                                        required
                                        name="prospectFirstName"
                                        label="First Name"
                                        variant="outlined"
                                        className={classes.textFiled}
                                        value={localState?.prospectFirstName}
                                        size="small"
                                        fullWidth
                                        inputProps={{ maxLength: 50 }}
                                        onChange={(e) => { HandleFirstNameVal(e); handleGetProspectUserDetails(e); }}
                                        helperText={firstnameerror}
                                        error={!!firstnameerror}
                                    />
                                </Grid>
                                <Grid item xs={6} mt={1}>
                                    <TextField
                                        id="outlined-basic"
                                        required
                                        name="prospectLastName"
                                        label="Last Name"
                                        variant="outlined"
                                        className={classes.textFiled}
                                        size="small"
                                        fullWidth
                                        inputProps={{ maxLength: 50 }}
                                        onChange={(e) => { HandleLastNameVal(e); handleGetProspectUserDetails(e); }}
                                        helperText={lastnameerror}
                                        error={!!lastnameerror}
                                    />
                                </Grid>
                                <Grid item xs={6} mt={1}>
                                    <TextField
                                        id="outlined-basic"
                                        required
                                        name="password"
                                        label="Password"
                                        variant="outlined"
                                        disabled={!!localState?.prospectEmailAddress?.length}
                                        type={showPassword ? 'text' : 'password'}
                                        size="small"
                                        fullWidth
                                        className={classes.textFiled}
                                        inputProps={{ maxLength: 50 }}
                                        onChange={(e) => { handleGetProspectUserDetails(e); handleValidatePassword(e) }}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        tabIndex={-1}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                                                    </IconButton>
                                                </InputAdornment>
                                        }}
                                        helperText={passwordError}
                                        error={!!passwordError}
                                    />
                                </Grid>
                                <Grid item xs={6} mt={1}>
                                    <TextField
                                        id="outlined-basic"
                                        required
                                        name="confirmPassword"
                                        label="Confirm Password"
                                        variant="outlined"
                                        disabled={!!localState?.prospectEmailAddress?.length}
                                        size="small"
                                        fullWidth
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        className={classes.textFiled}
                                        inputProps={{ maxLength: 50 }}
                                        onChange={(e) => { handleGetProspectUserDetails(e); handleConfirmPasswordChange(e); }}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowConfirmPassword} onMouseDown={handleMouseDownConfirmPassword}
                                                        tabIndex={-1}
                                                        edge="end"
                                                    >
                                                        {showConfirmPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                                                    </IconButton>
                                                </InputAdornment>
                                        }}
                                        helperText={confirmPasswordError}
                                        error={!!confirmPasswordError}
                                    />
                                </Grid>
                                <Grid item xs={12} mt={1}>
                                    <TextField
                                        id="outlined-basic"
                                        required
                                        name="prospectPhoneNumber"
                                        label="Phone Number"
                                        variant="outlined"
                                        className={classes.textFiled}
                                        size="small"
                                        fullWidth
                                        inputProps={{ maxLength: 16 }}
                                        onChange={(e) => { HandlePhoneVal(e); handleGetProspectUserDetails(e); }}
                                        helperText={phoneError}
                                        error={!!phoneError}
                                    />
                                </Grid>
                                <Grid item xs={12} mt={1} className={classes.capcha}>
                                    {captchaLoaded ? (
                                        <ReCaptchaComponent onVerify={handleCaptchaVerify} />
                                    ) : (
                                        <div>Loading captcha...</div>
                                    )}
                                </Grid>
                                <Grid item xs={12} mt={1}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        className={classes.continuebutton}
                                        disabled={(!!firstnameerror?.length || !!lastnameerror?.length || !!prospectEmailError?.length || !!phoneError?.length || !!passwordError?.length || !!confirmPasswordError?.length || !captchaResponse?.length) && isSubmitting}
                                        onClick={handleSignupClick}
                                    >
                                        CREATE ACCOUNT
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2" align="center">
                                        Already have an account?
                                        <Button variant="text" onClick={handleSignin}>
                                            <span style={{ color: "blue" }}><b>Sign In!</b></span>
                                        </Button>
                                    </Typography>
                                    <div className="line-container">
                                        <div className="hr-left"></div>
                                        <p className="line-text"><b>Or Continue with</b></p>
                                        <div className="hr-right"></div>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <Icon />
                                </Grid>
                            </Grid>
                            :
                            <Grid container spacing={1} >
                                <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'} p={1}>
                                    <img src={logo} height="50px" alt="companylogo" />
                                </Grid>
                                <Grid item xs={12} p={1}>
                                    <Typography variant="h5" color="#283593" pt={1} gutterBottom textAlign={'center'}><b>Hello, Welcome Back</b></Typography>
                                    <Typography variant="body1" color="gray" pt={1} gutterBottom textAlign={'center'}><b>Sign Up with Merry Learn</b></Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2" align="center" color="rgb(0, 75, 80)" gutterBottom className={classes.noteInfo} >
                                        <b>Merry Learn streamlines course management and provides users with progress tracking, grading, and notifications.</b>
                                    </Typography>
                                </Grid>
                                {loading && (
                                    <Grid item xs={12} className={classes.loader} p={1} display={'flex'} justifyContent={'center'}>

                                        <Typography variant="h6" color="textSecondary" style={{ marginTop: '10px' }}>   Please wait...</Typography>
                                    </Grid>
                                )}
                                {showSubscriptionMessage && (
                                    <Grid item xs={12} p={1}>
                                        <Typography gutterBottom style={{ color: 'red', justifyContent: 'center', display: 'flex', marginLeft: '20%' }}>
                                            <b>Please Verify Your Subscription</b>
                                        </Typography>
                                    </Grid>
                                )}
                                {active && !loading && (
                                    <Grid item xs={12} p={1}>
                                        <TextField
                                            id="outlined-basic"
                                            required
                                            name="productKey"
                                            label="Product Key"
                                            variant="outlined"
                                            value={subscriptionKey}
                                            className={classes.textFiled}
                                            size="small"
                                            fullWidth
                                            inputProps={{ maxLength: 50 }}
                                            onChange={(e) => { setSubscriptionKey(e.target.value); }}
                                        />
                                        <Button variant="contained" color="secondary" fullWidth className={classes.continuebutton} style={{ marginTop: '5px' }} onClick={handleVerifyKey} disabled={loading}>
                                            Verify
                                        </Button>
                                    </Grid>
                                )}
                                {!active && !loading && (
                                    <Grid item xs={12} mt={1}>
                                        <Button variant="contained" fullWidth className={classes.continuebutton} onClick={handleSignin}>
                                            Sign In
                                        </Button>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Typography variant="body2" align="center" style={{ padding: "4px" }}>
                                        Don't have an account?
                                        <Button variant="text" onClick={handleSignup}>
                                            <span style={{ color: "blue" }}><b>Sign Up!</b></span>
                                        </Button>
                                    </Typography>
                                    <div className="line-container">
                                        <div className="hr-left"></div>
                                        <p className="line-text"><b>Or Continue with</b></p>
                                        <div className="hr-right"></div>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <Icon />
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                :
                <AdminLayout />
            }
        </React.Fragment>
    );
};

export default Login2;
